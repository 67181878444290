:root{
--primamryBlue:#013757;
--primaryGrey:rgba(1,55,87,.4);
}

*{
margin:0;
padding:0;
box-sizing: border-box;
letter-spacing:.5px;
}
a{
  text-decoration:none;
 }
.child{
  padding:5px;
 
}
 .child:nth-child(odd){
  background:rgb(1,55,87,.3);
  
}
.child:hover{
  background:rgb(1,55,87,.6);
  cursor:pointer;
}
.highlight{
  background:rgb(255,255,0,.3);
}
.App {
  position:relative;
  width:100%;


}
.alert-success{
  width:100%;
  text-align:center;
  color:green;
margin-top:20px;
font-size:1.4rem;
  
  }
  .alert-shown {
      opacity: 1;
      transition: all 250ms linear;
    }
    
    .alert-hidden {
      opacity: 0;
      transition: all 250ms linear;
    }
.tabs{
  color:red;
}


@media print {
 .box{
  border:none;
  outline:none;
 }
 .box2{
  border:none;
  outline:none;
  background-color:#CCCCCC;
 }
}